<template>
  <form class="login-form d-flex flex-column border p-4 p-md-5">
    <div class="">
      <BasicLabel title="Full Name" :required="true" class="fw-bold" />
      <BasicInputText
        :hasRoundedCorners="true"
        class="login-input"
        :placeholder="'Full Name'"
        required
        v-model="name"
      />
    </div>
    <div class="login-field">
      <BasicLabel title="Email address" :required="true" class="fw-bold" />
      <BasicInputText
        :hasRoundedCorners="true"
        class="login-input"
        :placeholder="'Email'"
        required
        v-model="email"
      />
    </div>
    <div class="login-field-last">
      <BasicLabel title="Password" :required="true" class="fw-bold" />
      <BasicInputText
        :hasRoundedCorners="true"
        class="login-input"
        type="password"
        required
        :placeholder="'Password'"
        v-model="password"
      />
    </div>
    <div class="login-field">
      <BasicLabel title="Credit Score" :required="true" class="fw-bold" />
      <BasicInputText
        type="number"
        :hasRoundedCorners="true"
        class="login-input"
        :placeholder="'Credit Score'"
        required
        v-model="creditScore"
      />
    </div>
    <div class="login-field">
      <BasicLabel title="Will you finance with dealership?" class="fw-bold" >
        <template v-slot:before>
          <input
            class="form-check-input"
            type="checkbox"
            id="flexCheckVendor"
            name="flexCheckVendor"
            v-model="finance"
          />
        </template>
      </BasicLabel>
    </div>

    <div class="form-check my-3 mt-4">
      <!-- <basic-label
        :title="'I am a vendor'"
        class="fw-bold"
        :forInput="'flexCheckVendor'"
      >
        <template v-slot:before>
          <input
            class="form-check-input"
            type="checkbox"
            id="flexCheckVendor"
            name="flexCheckVendor"
          />
        </template>
      </basic-label> -->
    </div>
    <link-wrapper>
      <ButtonFilled
        @click.prevent="register"
        class="Minicart_button fw-bold text-white"
        :text="'Register'"
        :hasRoundedCorners="true"
      />
    </link-wrapper>
    <link-wrapper
      :location="'/login'"
      class="text-decoration-none text-dark fs-6 mt-3 fw-bold"
    >
      <span>Already have account? Login here</span>
    </link-wrapper>
  </form>
</template>

<script>
import ButtonFilled from "../../atoms/Button/ButtonFilled.vue";
import BasicInputText from "../../atoms/FormInput/BasicInputText.vue";
import BasicLabel from "../../atoms/FormInput/BasicLabel.vue";
import LinkWrapper from "../../atoms/LinkWrapper/LinkWrapper.vue";

export default {
  components: {
    ButtonFilled,
    BasicInputText,
    BasicLabel,
    LinkWrapper,
  },
  methods: {
    register() {
      this.$store.dispatch('register', {fullName: this.name, email: this.email, password: this.password, creditScore: this.creditScore, finance: this.finance})
    }
  }
};
</script>
<style scoped="scoped">
.customer-checkbox {
  margin-top: 10px;
}
.login-input {
  height: 40px !important;
  padding: 10px 20px !important;
  box-shadow: none !important;
  margin-top: 10px;
}
.form-check {
  padding-left: 27px !important;
}
.login-field,
.login-field-last {
  margin-top: 30px !important;
}

.Minicart_button {
  width: 150px !important;
  height: 40px !important;
}
@media screen and (max-width: 768px) {
  .login-input {
    width: 100% !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .login-input {
    width: 250px !important;
  }
  .form-check-input input {
    width: 15px !important;
  }
}
</style>
